"use client";

import {
  TextField,
  Stack,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import LayerContainer from "./LayerContainer/layerContainer";
import { useSelector } from "react-redux";

const CategoriesDialog = (props) => {
  const reduxLayers = useSelector(
    (state) => state.demo.keplerGl.map.visState.layers
  );
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState();

  useEffect(() => {
    if (props.data) setFiltered(props.data);
  }, [props.data]);

  const containerStyle = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    zIndex: 100,
    top: 10,
    right: 55,
    height: '93vh',
    backgroundColor: "white",
    padding: "1rem 1.25rem",
    width: 300,
  };

  function clickedElement(value) {
    props?.onChange?.(value);
  }

  function buildCategoriesList(layers, expanded) {
    return layers
      .filter((layer) => layer !== null)
      .map((layer, index) => {
        const currentReduxLayer = reduxLayers?.find((l) => l.id === layer.id);
        const isFolder = "children" in layer;

        return (
          <article key={index}>
            <LayerContainer
              id={layer.id ?? ""}
              title={layer.label}
              expanded={
                isFolder
                  ? expanded
                  : !currentReduxLayer?.config?.isVisible ?? false
              }
              hasChildren={layer.children?.length > 0 ?? false}
              onClick={clickedElement}
            >
              {buildCategoriesList(layer.children ?? [], expanded)}
            </LayerContainer>
          </article>
        );
      });
  }

  function filterCategoriesList(folder, searchValue) {
    const isAMatch = (value) =>
      value.toLowerCase().includes(searchValue.toLowerCase());

    return folder.map((item) => {
      const isFolder = () => item.children?.length > 0 ?? false;

      if (isAMatch(item.label)) return { ...item };
      else if (isFolder()) {
        const children = filterCategoriesList(item.children, searchValue);
        const isNotEmpty = () =>
          children.filter((foundItem) => foundItem !== null).length > 0;

        if (isNotEmpty()) return { ...item, children };
      }
      return null;
    });
  }

  useEffect(() => {
    const hasMinimun3Chars = () => search.length >= 3;

    if (search && hasMinimun3Chars()) {
      setFiltered(filterCategoriesList(props.data, search));
    } else {
      setFiltered(props.data);
    }
  }, [search]);
  if (!props.open) return null;

  return (
    <article style={containerStyle}>
      <Stack direction={"column"} gap={2}>
        <Typography variant="subtitle1" fontWeight={700}>
          Catalogo de mapas
        </Typography>
        <Stack>
          <TextField
            size="small"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            variant="outlined"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            placeholder="buscar capa"
          />
        </Stack>
        <Stack>
          <Box>{buildCategoriesList(filtered ?? [], search.length > 3)}</Box>
        </Stack>
      </Stack>
    </article>
  );
};

export default CategoriesDialog;
