import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Lottie from "react-lottie";
import keycloak from "./keycloak-config";
import Loading from "./assets/map-loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading,
  rendererSettings: {
    clearCanvas: true,
    progressiveLoad: true,
    preserveAspectRatio: "xMidYMid meet",
  },
};

const KeycloakProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  console.log("KeycloakProvider: authenticated", authenticated);

  useEffect(() => {
    keycloak
      .init({ onLoad: "login-required" })
      .then((auth) => {
        setAuthenticated(auth);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        console.error("Keycloak initialization error:", err);

        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={6}
        gap={2}
      >
        <Lottie options={defaultOptions} isClickToPauseDisabled height={220} />
        <Typography color="#7a7a7a">
          Accediendo a la aplicación de mapas
        </Typography>
      </Box>
    );
  }
  if (error) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={6}
        gap={2}
      >
        <Typography color="error">Error: No se pudo autenticar</Typography>
        <Button
          onClick={() => window.location.reload()}
          variant="contained"
          color="primary"
        >
          Reintentar
        </Button>
      </Box>
    );
  }

  if (authenticated === false) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={6}
        gap={2}
      >
        <Typography color="error">Error: No se pudo autenticar</Typography>
      </Box>
    );
  }

  return <>{children}</>;
};

export default KeycloakProvider;
