import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: '20px',
  '&.Mui-expanded': {
    minHeight: '0px',
  },
  '&.MuiAccordionSummary-content': {
    margin: '0px',
  }
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '8px 16px 8px', 
}));

export default function LayerContainer(props) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(props.expanded ?? false);
  }, [props.expanded]);

  const hasNoChildren = () => !props.hasChildren;

  function handleOnClick() {
    setExpanded(!expanded);
    if (hasNoChildren()) props.onClick?.(props.id);
  }
  return (
    <Accordion
      expanded={hasNoChildren() ? false : expanded}
      elevation={0}
      style={{ width: "100%" }}
    >
      <Box style={{ display: "flex", flexDirection: "row", height: '20px' }}>
        {// para carpetas
        props.hasChildren ? (
          expanded ? (
            <IconButton size="small" onClick={handleOnClick}>
              <FolderOpenIcon />
            </IconButton>
          ) : (
            <IconButton size="small" onClick={handleOnClick}>
              <FolderIcon />
            </IconButton>
          )
        ) : expanded ? (
          <IconButton size="small" onClick={handleOnClick}>
            <VisibilityOffIcon />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={handleOnClick}>
            <VisibilityIcon />
          </IconButton>
        )}

        <StyledAccordionSummary onClick={handleOnClick}>
          <Typography variant="body2"> {props.title} </Typography>
        </StyledAccordionSummary>
      </Box>
      {expanded && props.hasChildren && (
        <StyledAccordionDetails className="no_borders" style={{ paddingRight: 0 }}>
          <div className="children_container">{props.children}</div>
        </StyledAccordionDetails>
      )}
    </Accordion>
  );
}
