function buildTree(data) {
  const root = [];

  data.forEach(item => {
      const pathParts = item.path === '/' ? [] : item.path.split('/').filter(part => part);
      
      let currentLevel = root;

      // Caso donde el path es '/'
      if (item.path === '/') {
          currentLevel.push({
              id: item.id,
              dataId: item.dataId,
              label: item.label,
              color: item.color,
              highlightColor: item.highlightColor
          });
          return;
      }

      // Construcción del árbol para paths regulares
      pathParts.forEach((part, index) => {
          let existingPath = currentLevel.find(node => node.label === part);

          if (!existingPath) {
              existingPath = { label: part, children: [] };
              currentLevel.push(existingPath);
          }

          if (index === pathParts.length - 1) {
              existingPath.children.push({
                  id: item.id,
                  dataId: item.dataId,
                  label: item.label,
                  color: item.color,
                  highlightColor: item.highlightColor
              });
          }

          currentLevel = existingPath.children;
      });
  });

  return root;
}
export const getLayersFromKepler = (keplerGl) => {
  const layers = keplerGl.config.config.visState.layers;
  let geojsonLayers = [];
  layers
    .filter((layer) => layer.config?.path)
    .forEach((layer) => {
      // if (layer.type === "geojson") {
        geojsonLayers.push({
          id: layer.id,
          path: layer.config?.path,
          dataId: layer.config.dataId,
          label: layer.config.label,

          highlightColor: layer.config.highlightColor,

          isVisible: layer.config.isVisible,

          hidden: layer.config.hidden,
          textLabel: layer.config.textLabel,
        });
      // }
    });
  console.log("geojsonLayers", geojsonLayers);
  return buildTree(geojsonLayers);
};
