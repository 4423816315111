// @ts-check

import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "./button";
import {
  addDataToMap,
  loadFiles,
  MapStyleActions,
  toggleModal,
  startExportingImage,
  setLocale,
  toggleSidePanel,
  setExportImageDataUri,
  layerConfigChange,
} from "@kepler.gl/actions";

import { injectComponents, PanelHeaderFactory } from "@kepler.gl/components";
import KeplerGlSchema from "@kepler.gl/schemas";
import theme from "./theme";
import downloadJsonFile from "./file-download";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import DownloadIcon from "@mui/icons-material/Download";
// import ImageIcon from "@mui/icons-material/Image";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

import { v4 as uuidv4 } from "uuid";
import ExportMap from "./exportMap";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { processKeplerglJSON } from "@kepler.gl/processors";
import Loading from "./assets/map-loading.json";
import mapStyleJson from "./assets/map-styles.json";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  LinearProgress,
} from "@mui/material";
import Lottie from "react-lottie";

import { getJsonItem } from "./getJsonItem";

import { EXPORT_IMAGE_ID } from "@kepler.gl/constants";
import api from "./features/api";
import { getLayersFromKepler } from "./utils/getLayersFromKepler";
// import keplerLoco from "./assets/kepler.loco.json";

import CategoriesDialog from "./categoriesDialog";
import { isEqual } from "lodash";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading,
  rendererSettings: {
    clearCanvas: true,
    progressiveLoad: true,
    preserveAspectRatio: "xMidYMid meet",
  },
};

const CustomHeader = ({ showExportImageModal }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    }}
  >
    <div>
      <p style={{ color: theme.labelColor, margin: "3px 0px 1px 8px" }}>
        <b>Maps Analytics</b>
      </p>
      <p
        style={{ color: theme.labelColor, fontSize: "10px", margin: "0px 8px" }}
      >
        2024.3.0.0
      </p>
    </div>
  </div>
);

// Create a factory for custom header
const myCustomHeaderFactory = (PanelHeader) => (props) => (
  <CustomHeader {...props} showExportImageModal={props.showExportImageModal} />
);

// Inject custom header into Kepler.gl,
const KeplerGl = injectComponents([
  // @ts-ignore
  [PanelHeaderFactory, myCustomHeaderFactory],
]);

const MAPBOX_TOKEN = process.env.MAPBOX_TOKEN;
const API_URL = process.env.API_URL;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlMap: "",
      showAlert: false,
      alertMessage: "",
      alertResponse: "",
      progress: 0,
      loading: { bool: false, msg: "" },
      showModalDinamic: false,
      componentLayers: [],
    };

    this.timer = null;
  }

  setLoading({ bool, msg }) {
    this.setState((state) => ({
      ...state,
      loading: { ...state.loading, bool, msg },
    }));
  }

  calculateRemainingTime = () => {
    const { progress } = this.state;

    if (progress === 0) {
      return "Calculating...";
    }

    if (progress === 100) {
      return "Cargando...";
    }

    const elapsedTimeInSeconds = (Date.now() - (this.startTime || 0)) / 1000;

    const estimatedTotalTimeInSeconds = elapsedTimeInSeconds / (progress / 100);
    const remainingTimeInSeconds =
      estimatedTotalTimeInSeconds - elapsedTimeInSeconds;

    const minutes = Math.floor(remainingTimeInSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(remainingTimeInSeconds % 60)
      .toString()
      .padStart(2, "0");

    return `${minutes}' ${seconds}''`;
  };

  changeStyleMap() {
    const mapStyles = mapStyleJson;
    console.log("changeStyleMap----", mapStyles);
    const defaultStyleId = "dark-matter";
    if (mapStyles.length > 0) {
      mapStyles.forEach((style) => {
        const mapboxStyle = {
          id: style.name,
          style: style.mapStyle,
          label: style.name,
          icon: style.icon,
          layerGroups: [],
          visible: true,
          url: style.mapStyle,
          token: MAPBOX_TOKEN,
        };
        this.props.dispatch(
          MapStyleActions.loadMapStyles({ [style.name]: mapboxStyle })
        );
      });

      const firstStyle = mapStyles[0].name || defaultStyleId;
      this.props.dispatch(MapStyleActions.mapStyleChange(firstStyle));
    } else {
      this.props.dispatch(MapStyleActions.mapStyleChange(defaultStyleId));
    }

    console.log("Styles loaded", this.props);
  }

  componentDidMount() {
    const message = { type: "FRAME", text: "Frame cargado" };
    // Envía el mensaje a la página padre
    window.parent.postMessage(message, "*");
    this.changeStyleMap();
    this.props.dispatch(setLocale("es"));
    /* const {
      params: { id, provider } = {},
      location: { query = {} } = {},
    } = this.props;

    const cloudProvider = CLOUD_PROVIDERS.find((c) => c.name === provider);
    if (cloudProvider) {
      this.props.dispatch(
        loadCloudMap({
          loadParams: query,
          provider: cloudProvider,
          onSuccess: onLoadCloudMapSuccess,
        })
      );
      return;
    } */

    const dynamicURLPromise = new Promise((resolve, reject) => {
      console.time("listener");
      const messageHandler = (event) => {
        if (event.source !== parent) return;
        if (event.data.type === "dynamicURL") {
          const dynamicURL = event.data.url;
          const uidEvent = new CustomEvent("uidReceived", {
            detail: { uid: event.data.uId },
          });
          window.dispatchEvent(uidEvent);
          console.log("dynamicURL", dynamicURL, event.data);
          removeEventListener("message", messageHandler);
          if (dynamicURL) resolve(dynamicURL);
          else reject("url not obtained");
          console.timeEnd("listener");
          console.log();
          this.changeStyleMap();
        }
      };
      // resolve('../public/kepler.loco.json');
      // resolve("../public/kepler2.gl.json");
      // resolve(
      //   "https://nuv-dev-maps-analytics.s3.amazonaws.com/243afa4d-1bae-4ea6-8739-b672f2e25d85.map.json?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJH%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQD2ufEXTfTPk3tkIBt%2FW99fInDimQKJs8X5yQ31bPX3jwIgWTPVmp9hIXFNKrakRmG%2Bgyxc3gfSgk3D07BzIUYLTsMqjwQIehAAGgw4NzQ2NDE5MTI3NzciDJ1msHt5LY2BE7JSKirsAw24GnmUyx%2BUljRHeV05CL8eKINmTPo2ssOOgcLI9cwraTNwFf9%2FbdnF%2Bdg7wE%2BCjh1ACGkc8NEsfYUaKMjDmbr3LuVP362PsMR2k5fdqmSQkUAyRfTBsblweJV%2FT5jidsnkmRJJpgh4bYffdO1KNgFrRjGCHNXrFE9l8Qe5%2FtszX94WXYQcSbCqO%2BmfS%2FfpVVaJXHwcSsAcEf7niLNe2RUBcqJ18KlhxF%2Fpl0GaRwLKZWbb3Z5SohmADeUlD0eLn5Kejxv5dD0qlrYP3VQZq3p5Yge9%2FWjwn%2BibK42upozD7P18bf4R%2F5XQfWHqatZifTnYe2QIMN0WIFGyYkcAPE4f3EweAKN5elyYchjZCwqsRqal0mCFM8OQOskSoHQ3k7FN7F0xW0iRL%2FX29aNss3cSRIEKnP%2F8dBCUwJLLJye2YAGrBabgWLsB2Uuj7DNM3cIa6tdOqPsFlvvNxqBU5K%2FBLJR7UbNHH%2FSMPKqUj47vOOW%2B2gsYdFVxRPxYRlCou0tqZ61lHgsBK2gcDYrv%2FTgox%2F0IRlMDaG8KZh3nDzljLGzOV2TPMxqLiq0AQ3YRPeun2dGG0MR2XX2DqLpDodmo4bIqzpciCQpZlmFEO96%2FWGkw8lbq7eq1WRN5a9pELVS9uEDjigCC1LGvGTDx%2F661BjqmAZ%2FJxvBq92ytTsICzRV95ka9%2FXj00ms0hkaiKso8aXTo%2FZSYlOnw6Z3WO%2FqeNPPktugzbPJULDo8dwQBnfKBm1dkVoqTOEY20ehPl%2F8XgLxN2pn2Oz8rm4sgT6LgtBAl9I8tPl8qHZB6LgF1DuYrjjv8zpUs5%2BwrxQPE4IhOKGhQzhz%2FYFEAHAT8LVyeJgvZqhEKTkRCRHTafiVTSpO48eczW4xC0%2Bc%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240801T171513Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Credential=ASIA4XJFYOPER5APGQNW%2F20240801%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=fb6229065e87440b67e17137feccfef2de5c0b682f6fc9adf6444d6402fc3cdd"
      // );
      window.addEventListener("message", messageHandler);
    });
    console.time("request");

    dynamicURLPromise.then((dynamicURL) => {
      this.startTime = Date.now();
      this.setLoading({ bool: true, msg: "Loading Map" });
      const name = dynamicURL
        .split("/")
        .pop()
        .split("?")[0];
      this.props.dispatch(toggleSidePanel(null));
      this.setState({
        urlMap: name,
      });

      getJsonItem(dynamicURL, {
        onProgress: ({ fraction }) => {
          this.setState({ progress: Math.round(fraction * 100) });
        },
      })
        .then((datageo) => {
          try {
            const treeLayers = getLayersFromKepler(datageo);
            console.log("treeLayers", treeLayers);
            const keplerGL = processKeplerglJSON(datageo);

            // @ts-ignore
            if (keplerGL) this.props.dispatch(addDataToMap(keplerGL));
            if (treeLayers.length > 0)
              this.setState({
                ...this.state,
                showModalDinamic: true,
                categoryLayers: treeLayers,
              });
          } catch (error) {
            console.error(error);
            const myblob = new Blob([JSON.stringify(datageo)]);
            const files = new File([myblob], name);
            this.props.dispatch(loadFiles([files]));
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          clearInterval(this.timer);
          this.setLoading({ bool: false, msg: "" });
          console.timeEnd("request");
        });
    });

    console.log("urlMap", this.state.urlMap);

    console.log(this.props);

    // ? getJsonItem(
    // ?   "https://nuv-dev-maps-analytics.s3.amazonaws.com/a9f4f009-b19b-4e9b-89c0-f7722233451d.map.json?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEgaCXVzLWVhc3QtMSJHMEUCIQDtC0TLIrUfSY7G6g2SRXPpw%2FQzFiimZlolxjZIilFfMwIgLo%2Fe45J7Xo3XETfehfi4krEC8GuwRv%2FGXI3HM1Bcj%2F8qmAQIsP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw4NzQ2NDE5MTI3NzciDFRvZ2las3H7c0s7TSrsA8ID2Z8r7Rt6YopqGPNt%2Fzi0fmOVw%2FzftzNTXiY4k%2Bu0w3FKqXBzzg95cEXutVnum5rkC3hj7HyVVphl30jw2ul5YpHyvD09tv16uvlWGLc2j0dRzq4TOgyN3DwpqXmTfvPwENq8QKJMOvmne5LNHDU%2Bd3faVwK7aaTOHRgB6HdcohuuEwR8O6Dr5iWbmJ9KH%2FSJOzgVbb1y0g7xD8YmYnB21H67utWbCxnt6qq%2Bfv9MsH2G5AlWAJCeujy1ckGLM9rLjYCG68FEFelQh8fprvwYs8t2Jzr4tbZ%2BWtMGJijsnErxj43ilIYITcrfSn3rFD98JGiZ8yROc%2FTR7GI24ObXD3dl8Il%2BiPCkR8q%2BstQjskiKEwtUdVDipxje8YW8B6naE7%2FUgiHtw6SivkOr36nzq%2FELMNuN%2FVb1VMe%2FyUdt5sTJ9JABZzQYhMZblEGTVbBR71bAEOGr0TA5RzU6ywlOyjrrHQo9rXGXR4FM5AsK%2BTyK2CBZOHJlTYSJwUYdPvwPAzgZh9WZpx0MqbdvXkANo7OpFaScA91lxvxRbC20As1OC76qgURA8mPWM%2Fh%2BOL9Qj5tYPMWf2WhnlfN9nSW9%2FpIG6v1sETJNsPrfUQ7x73OdxJ1QwI63xMWy3q%2Bd7aUb1y0n6Wluhz2bATCshZWyBjqmARJEuSzrXBBuogC5NkD5ywiTUtqehmvjc%2BwCdWDunhiU55fh4YHCKf%2BDEsFPWRuDoV9aorx4t7mvgTCPftMHNhV5Ti48VlG7y%2BTBnBPDXDyA0hFplxuKT9dGRuhW2eW2Wt33N7cn3rMUo38Rsek3NnNmYqgxPFr3nBEPogiMtey0MDGZtIbL%2FMZdslPzHF%2FQ5o1DzX%2FscDUXzl8pd93s7BM2o%2Fpps1E%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240515T232429Z&X-Amz-SignedHeaders=host&X-Amz-Expires=599&X-Amz-Credential=ASIA4XJFYOPESLT7OOWZ%2F20240515%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=84218c02b6c0c3ce81a066facbc89e6d0828d6cbbc743cbb232b45db4511475e",
    // ?   { onProgress: ({ fraction }) => console.log({ fraction }) }
    // ? ).then((res) => console.log({ res }));
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("prevState", prevState,'state', this.state);
    const oldLayers = prevProps.demo.keplerGl.map.visState.layers
    const newLayers = this.props.demo.keplerGl.map.visState.layers
    if (!isEqual(oldLayers, newLayers)) {
      
      console.log('layers changed');
      console.log('oldLayers', oldLayers, 'newLayers', newLayers);
      // actualizar estado de las categorías
    }
  }

  showExportImageModal = async () => {
    console.log(this.props);
    if (this.props && this.props.dispatch) {
      this.props.dispatch(toggleModal(EXPORT_IMAGE_ID));
      this.props.dispatch(startExportingImage());
    } else {
      console.error("dispatch no está disponible en props");
    }
  };
  getMap() {
    // retrieve kepler.gl store
    const { demo } = this.props;
    const { keplerGl } = demo;
    // retrieve current kepler.gl instance store
    const { map } = keplerGl;
    // create the config object
    return KeplerGlSchema.save(map);
  }

  downloadMap = () => {
    // create the config object
    const map = this.getMap();
    // save it as a json file
    const { urlMap } = this.state;
    console.log("urlMap", urlMap);
    if (urlMap === "") {
      let uuid = uuidv4();
      let uuidWithExtension = uuid.concat(".map.json");
      this.setState({ uuid: uuidWithExtension }, () => {
        downloadJsonFile(map, uuidWithExtension);
      });
      console.log("uuid", uuidWithExtension);
      this.changeStyleMap();
    } else {
      downloadJsonFile(map, urlMap);
    }
  };

  saveMap = async (uuidNumber) => {
    this.setLoading({ bool: true, msg: "Saving Map" });
    const map = this.getMap();
    const uuid = uuidv4();
    let uuidWithExtension = uuid.concat(".map.json");
    const myblob = new Blob([JSON.stringify(map)], {
      type: "application/json",
    });
    const file = new File([myblob], uuidWithExtension, {
      type: "application/json",
    });
    const formData = new FormData();
    const imageBlob = await this.downloadImageBlob();
    console.log("imageBlob", imageBlob);
    formData.append("thumbnail", imageBlob, "kepler-map.png");
    formData.append("file", file);

    try {
      await api.put(`/items/updateJson/${uuidNumber}`, formData);

      // const responseData = await response.json();
      this.setState({ showAlert: true });
      this.setState({ alertMessage: "Map saved successfully" });
      this.setState({ alertResponse: "success" });
      this.setLoading({ bool: false, msg: "Saving Map" });
    } catch (error) {
      console.error("Error saving the map");
      this.setState({ showAlert: true });
      this.setState({ alertMessage: "Error saving the map" });
      this.setState({ alertResponse: "error" });
      this.setLoading({ bool: false, msg: "Saving Map" });
    }
  };

  dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  downloadImageBlob = async () => {
    return new Promise(async (resolve, reject) => {
      console.log(this.props);
      if (this.props && this.props.dispatch) {
        console.log("entra");
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        await this.props.dispatch(
          startExportingImage({
            ratio: "16:9",
            resolution: "1x",
            // @ts-ignore
            mapH: screenHeight,
            mapW: screenWidth,
          })
        );
        const checkImageExport = () => {
          const state = this.props.demo.keplerGl.map;
          if (state && state.uiState && state.uiState.exportImage) {
            const { imageDataUri } = state.uiState.exportImage;
            console.log("imageDataUri", imageDataUri);
            if (imageDataUri) {
              this.props.dispatch(setExportImageDataUri(imageDataUri));
              const imageBlob = this.dataURItoBlob(imageDataUri);
              const formData = new FormData();
              formData.append("file", imageBlob, "kepler-map.png");
              console.log("FormData listo para enviar:", formData);
              const imageVariable = imageDataUri;
              console.log("Imagen guardada en variable:", imageVariable);
              clearInterval(imageCheckInterval);
              resolve(imageBlob);
            }
          }
        };
        const imageCheckInterval = setInterval(checkImageExport, 2000);
        console.log("imageCheckInterval", imageCheckInterval);
      } else {
        console.error("dispatch no está disponible en props");
        reject(new Error("dispatch no está disponible en props"));
      }
    });
  };

  exportMap = async (formData) => {
    this.setLoading({ bool: true, msg: "Saving Map" });
    const map = this.getMap();
    const uuid = uuidv4();
    let uuidWithExtension = uuid.concat(".map.json");
    // formData.append("item_uuid", uuid);
    console.log(uuid);
    const myblob = new Blob([JSON.stringify(map)], {
      type: "application/json",
    });
    const file = new File([myblob], uuidWithExtension, {
      type: "application/json",
    });
    formData.append("file", file);
    console.log("formData", formData);
    const imageBlob = await this.downloadImageBlob();
    console.log("imageBlob", imageBlob);
    formData.append("thumbnail", imageBlob, "kepler-map.png");
    try {
      await api.post(`/items/upload`, formData);

      // const responseData = await response.json();
      this.setState({ showAlert: true });
      this.setState({ alertMessage: "Map saved successfully" });
      this.setState({ alertResponse: "success" });
    } catch (error) {
      console.error("Error submitting form", error);
      console.error("Failed to submit form");

      this.setState({ showAlert: true });
      this.setState({ alertMessage: "Error saving the map" });
      this.setState({ alertResponse: "error" });
    } finally {
      this.changeStyleMap();
      this.setLoading({ bool: false, msg: "" });
    }
    this.changeStyleMap();
  };

  render() {
    // console.log(this.props);
    const { showAlert, progress, loading } = this.state;
    return (
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <Button
          top={210}
          message={"Categorias"}
          onClick={() =>
            this.setState({
              ...this.state,
              showModalDinamic: !this.state.showModalDinamic,
            })
          }
          invert={this.state.showModalDinamic}
        >
          <MenuBookIcon />
        </Button>
        <Button top={246} message={"Export Map"} onClick={this.downloadMap}>
          <DownloadIcon />
        </Button>
        <Button
          top={284}
          message={"Export image"}
          onClick={this.showExportImageModal}
        >
          <InsertPhotoIcon />
        </Button>
        <ExportMap onSubmit={this.exportMap} onSubmitSave={this.saveMap} />
        <CategoriesDialog
          data={this.state.categoryLayers}
          open={this.state.showModalDinamic}
          onChange={(value) => {
            const layers = this.props.demo.keplerGl.map.visState.layers;
            const selectedLayer = layers.find((layer) => layer.id === value);
            if (selectedLayer) {
              this.props.dispatch(
                layerConfigChange(selectedLayer, {
                  isVisible: !selectedLayer.config.isVisible,
                })
              );
            } else {
              console.error(`${value}`);
            }
          }}
        />

        <Dialog open={loading.bool} transitionDuration={300}>
          <DialogContent>
            <Box
              display="flex"
              alignItems="center"
              flexDirection={"column"}
              gap={2}
              height={"50%"}
              p={1}
              borderRadius={10}
            >
              <Lottie
                options={{ ...defaultOptions }}
                isClickToPauseDisabled
                height={50}
              />
              <Typography color="#7a7a7a">{loading.msg}</Typography>
              <Box width="100%">
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    "& .MuiLinearProgress-bar": { backgroundColor: "Black" },
                  }}
                  style={{ backgroundColor: "#e0e0e0", width: "200px" }}
                />
              </Box>
              <Typography color="#7a7a7a">
                Tiempo restante: {this.calculateRemainingTime()}
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={showAlert}
          autoHideDuration={5000}
          onClose={() => this.setState({ showAlert: false })}
        >
          <Alert
            onClose={() => this.setState({ showAlert: false })}
            // @ts-ignore
            severity={this.state.alertResponse}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {this.state.alertMessage}
          </Alert>
        </Snackbar>

        <AutoSizer>
          {({ height, width }) => (
            <KeplerGl
              mapboxApiAccessToken={MAPBOX_TOKEN}
              id="map"
              getState={(state) => ({
                ...state.demo.keplerGl,
              })}
              width={width}
              height={height}
              theme={theme}
              mapStyle={{ styleType: "positron" }}
              //cloudProviders={CLOUD_PROVIDERS}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

const dispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = (state) => state;

export default connect(mapStateToProps, dispatchToProps)(App);
