/**
 * Fetches a JSON item from a given URL with progress tracking.
 * 
 * @param {string} url - The URL to fetch the JSON item from.
 * @param {Object} options - The options for progress and completion callbacks.
 * @param {onProgressCallback} [options.onProgress] - Callback function for progress updates.
 * @param {onCompleteCallback} [options.onComplete] - Callback function for completion.
 * @returns {Promise<Object>} A promise that resolves to the fetched JSON object.
 */
export const getJsonItem = (url, { onProgress, onComplete }) => 
    new Promise((resolve, reject) => {
      const handleProgress = async () => {
        try {
          // Step 1: start the fetch and obtain a reader
          const response = await fetch(url);
          if (!response.ok) reject("Error fetching");
  
          const reader = response.body.getReader();
  
          // Step 2: get total length
          const contentLength = +response.headers.get("Content-Length");
  
          // Step 3: read the data
          let receivedLength = 0; // received that many bytes at the moment
          const chunks = []; // array of received binary chunks (comprises the body)
          while (true) {
            const { done, value } = await reader.read();
  
            if (done) {
              break;
            }
  
            chunks.push(value);
            receivedLength += value.length;
            onProgress?.({
              receivedLength,
              chunks,
              currentChunk: value,
              done,
              fraction: receivedLength / contentLength,
            });
          }
  
          // Step 4: concatenate chunks into single Uint8Array
          let chunksAll = new Uint8Array(receivedLength); // (4.1)
          let position = 0;
          for (let chunk of chunks) {
            chunksAll.set(chunk, position); // (4.2)
            position += chunk.length;
          }
  
          // Step 5: decode into a string
          const result = new TextDecoder("utf-8").decode(chunksAll);
  
          // We're done!
          onComplete?.(result);
          resolve(JSON.parse(result));
        } catch (error) {
          reject(error);
        }
      };
  
      handleProgress();
    });
  
  /**
   * Callback for progress updates.
   * @callback onProgressCallback
   * @param {Object} progress - The progress object.
   * @param {number} progress.receivedLength - The number of bytes received so far.
   * @param {Uint8Array[]} progress.chunks - The array of received binary chunks.
   * @param {Uint8Array} progress.currentChunk - The current chunk of data being processed.
   * @param {boolean} progress.done - Whether the fetch is complete.
   * @param {number} progress.fraction - The fraction of the total content length received.
   */
  
  /**
   * Callback for completion.
   * @callback onCompleteCallback
   * @param {Object} result - The fetched JSON object.
   */
  