import { useState } from "react";
import React from "react";
import theme from "./theme";

const handleHover = () => {
  buttonStyle.color = "#ffffff";
};


/**
 * Componente Button
 * 
 * @param {Object} props - Props del componente.
 * @param {function} props.onClick - Función que se ejecuta al hacer clic en el botón.
 * @param {React.ReactNode} props.children - Contenido que se mostrará dentro del botón.
 * @param {string|number} props.top - Posición superior del botón en la pantalla.
 * @param {string} props.message - Mensaje que se muestra al hacer hover sobre el botón.
 * @param {boolean} [props.invert] - (Opcional) Si es verdadero, invierte los colores del botón.
 * 
 * @returns {JSX.Element} - El componente Button.
 */
const Button = ({ onClick, children, top, message, invert }) => {
  const [isHover, setIsHover] = useState(false);
  const [mostrarMensaje, setMostrarMensaje] = useState(false);

  const buttonStyle = {
    position: "absolute",
    zIndex: 100,
    top: top,
    right: 12,
    width: "32px",
    height: "32px",
    color: theme.primaryBtnColorper,
    backgroundColor: theme.primaryBtnBgdper,
    cursor: "pointer",
    border: 0,
    borderRadius: "3px",
    fontSize: "15px",
    transition: "all 0.3s ease-in-out",
  };

  const divStyle = {
    position: "absolute",
    display: "flex",
    zIndex: 100,
    top: top,
    right: 55,
    width: "80px",
    height: "32px",
    cursor: "pointer",
    border: 3,
    borderRadius: "2px",
    backgroundColor: theme.primaryDivBgdper,
    justifyContent: "center",
    alignItems: "center",
  };

  const messegeStyle = {
    fontSize: "10px",
    color: theme.primaryBtnColorper,
    textAlign: "center",
    fontFamily: "ff-clan-web-pro",
  };

  const handleMouseEnter = () => {
    setIsHover(true);
    setMostrarMensaje(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    setMostrarMensaje(false);
  };

  return (
    <div>
      <button
        style={{
          ...buttonStyle,
          ...(invert && {
            color: theme.primaryBtnBgdper,
            backgroundColor: theme.primaryBtnColorper,
          }),
        }}
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        {children}
      </button>
      {mostrarMensaje && (
        <div>
          <div style={divStyle}>
            <p style={messegeStyle}>{message}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Button;
