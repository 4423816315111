
import ReactDOM from "react-dom/client";
import document from "global/document";
import { Provider } from "react-redux";
import store from "./store";
import App from "./app";

import KeycloakProvider from "./KeycloakProvider";

import { BrowserRouter } from "react-router-dom";

const Root = () => (
  <Provider store={store}>
    <BrowserRouter>
      <KeycloakProvider>
        <App />
      </KeycloakProvider>
    </BrowserRouter>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Root />);
