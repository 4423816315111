import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Maps from "./Maps";
import HealthCheck from "./healtCheck";

const App = () => {
  return (
    <Suspense fallback={() => <div style={{ backgroundColor: "red" }}>Cargando...</div>}>
      <Routes>
        <Route path="/" element={<Maps />} />
        <Route path="/healthCheck" element={<HealthCheck />} />
      </Routes>
    </Suspense>
  );
};

export default App;
